import React from "react";
import loadable from "@loadable/component";
import { isMobile } from "react-device-detect";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/LargeFeaturesImages";

import {
  hero,
  checklist,
  largeFeatures,
  heroSuccess,
  BarbwireBbqSlides,
  team,
  footer,
  largeFeature1,
  largeFeature2,
  largeFeature3,
  largeFeature4,
} from "../../../data/landing/restaurant-pop-data";

import bg from "../../../images/calculator/background.png";
import bgMobile from "../../../images/calculator/background-mobile.png";
import vector from "../../../images/svg/vector-04.svg";
import check from "../../../images/svg/checkmark.svg";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import FeaturesWithThumbnails from "../../../components-v2/FeaturesWithThumbnails";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const Hero = loadable(() =>
  import("../../../page-components/points-of-profit/hero")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);

const RestaurantPop = () => {
  return (
    <Layout
      className="non-fixed-width"
      transparentHeader
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="New Restaurant Technology Guide | How to Open a Restaurant | SpotOn"
        description="Learn how to open a restaurant successfully by incorporating technology to attract guests, operate efficiently, maximize sales, and manage & retain employees."
      />

      <Section fullWidth animations={false} className="bg-black">
        <img
          className="absolute -top-10 right-0 z-0"
          src={vector}
          alt="background"
        />

        <SimpleHero
          className="mt-32 lg:mt-40 mx-auto"
          customHeadingSize="md:max-w-70-percent lg:max-w-90-percent xl:max-w-70-percent"
          {...hero}
          titleCustomClass="text-white"
          paddingBottom="pb-40 lg:pb-56"
        />
      </Section>

      <Section fullWidth fullWidthCarousel className="max-w-[1360px] mx-auto">
        <Hero
          imageName="restaurant-pop.png"
          mobileImageName="restaurant-pop-mobile.png"
          sideText=""
          componentSizing="-mt-32 lg:-mt-52"
        />
      </Section>

      <Section className="mt-12 lg:mt-28 relative">
        {/* <ul className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-y-[14px]"> */}
        <ul
          className={`
          mx-auto flex flex-row flex-wrap justify-between items-start
          gap-x-10 gap-y-4 pl-0
          max-w-[1120px]
        `}
        >
          {checklist.map((item) => (
            <li
              key={item}
              className="flex flex-row items-start lg:max-w-[340px] xl:max-w-[460px]"
            >
              <img src={check} alt="checkbox" className="mr-8 mt-2" />
              {/* <div className="text-black text-[24px] font-bold">{item}</div> */}
              <Heading level={4} injectionType={4} className="text-black">
                {item}
              </Heading>
            </li>
          ))}
        </ul>
      </Section>

      <FeaturesWithThumbnails className="" {...largeFeature1} />
      <FeaturesWithThumbnails className="" {...largeFeature2} />
      <FeaturesWithThumbnails className="" {...largeFeature3} />
      <FeaturesWithThumbnails className="" {...largeFeature4} />

      {/* <Section className="mt-32"> */}
      {/*  <LargeFeatures sectionData={largeFeatures} /> */}
      {/* </Section> */}

      <Section className="mt-12 lg:mt-28">
        <SuccessStories sectionData={heroSuccess} slides={BarbwireBbqSlides} />
      </Section>

      <Section className="text-center mt-12 lg:mt-16">
        <Heading
          level={2}
          injectionType={2}
          className="text-black"
          withBlueSymbol
        >
          {team.title}
        </Heading>

        {team.paragraphs.map((p) => (
          <p
            key={p}
            className="text-black-300 text-[20px] mt-3 max-w-[900px] mx-auto text-left"
          >
            {p}
          </p>
        ))}

        <CtaPrimary className="mt-10 mx-auto" {...team.cta} />
      </Section>

      <Section className="mt-12 lg:mt-16">
        <Heading
          className="text-black max-w-[800px] mx-auto text-center"
          withBlueSymbol
          level={2}
          injectionType={2}
        >
          SpotOn is the top-rated POS company according to real user reviews
        </Heading>

        <CtaPrimary
          className="mx-auto"
          title="See the results"
          target="https://spoton.com/blog/real-users-prefer-spoton-vs-toast-competitors/"
          newTab
        />

        <Image
          imageName="capterra-best-restaurant-pos.png"
          className="mx-auto max-w-[630px]  mt-6 lg:mt-10"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
      </Section>

      <Section className="mt-6 md:mt-10">
        <Image
          className="hidden md:block max-w-[1120px] mx-auto"
          imageName="capterra-badges.png"
        />
        <Image
          className="md:hidden mx-auto"
          imageName="capterra-badges-mobile.png"
        />
      </Section>

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
      />
    </Layout>
  );
};

export default RestaurantPop;
