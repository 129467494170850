import React from "react";
import { string, arrayOf, bool, shape } from "prop-types";
import Section from "../Base/Section";
import Image from "../../components/ImageQuerys/ResourceImages";
import Heading from "../Base/HeadingBuilder";
import CtaPrimary from "../Base/CtaPrimary";

const FeaturesWithThumbnails = ({
  className,
  sectionTitle,
  title,
  subTitle,
  features,
  cta,
  darkMode,
}) => {
  return (
    <Section
      className={`
        ${darkMode ? "bg-black" : "bg-white"}
        ${className}
      `}
      fullWidth
      fullWidthCarousel
    >
      {sectionTitle && (
        <Heading
          level={2}
          injectionType={2}
          withBlueSymbol
          className={`
            mx-auto text-center my-10 md:my-20
            ${darkMode ? "text-white" : "text-black"}
          `}
        >
          {sectionTitle}
        </Heading>
      )}
      <div className="max-w-[1110px] mx-auto py-20 px-4 md:px-10 xl:px-0">
        <Heading
          level={3}
          injectionType={3}
          withBlueSymbol
          className={`mx-auto text-center ${
            darkMode ? "text-white" : "text-black"
          }`}
        >
          {title}
        </Heading>

        <p
          className={`
        mx-auto my-6 max-w-[825px] text-center
        ${darkMode ? "text-black-500" : "text-black-200"}
      `}
        >
          {subTitle}
        </p>

        {cta && <CtaPrimary className="mx-auto" {...cta} />}

        <div
          className={`
          mt-12
          grid gap-x-7 gap-y-14 grid-flow-row-dense
          grid-cols-1
          md:grid-cols-2
          lg:grid-cols-3
        `}
        >
          {features.map((feature) => (
            <a
              key={feature.title}
              href={feature.target}
              target="_blank"
              rel="noreferrer"
              className={`
                group
                flex flex-col
                ${feature.isLarge ? "md:col-span-2" : ""}
              `}
            >
              <div className="overflow-hidden rounded-[16px]">
                <Image
                  imageName={feature.imageName}
                  className={`
                    min-h-[290px] rounded-[16px]
                    transition transition-all duration-300
                    group-hover:rotate-[15deg]
                    group-hover:scale-125
                  `}
                />
              </div>
              <div
                className={`text-[18px] mt-4
                group-hover:underline
                ${darkMode ? "text-white" : "text-black"}
              `}
              >
                {feature.title}
              </div>
            </a>
          ))}
        </div>
      </div>
    </Section>
  );
};

FeaturesWithThumbnails.propTypes = {
  className: string,
  sectionTitle: string,
  title: string.isRequired,
  subTitle: string.isRequired,
  features: arrayOf(
    shape({
      imageName: string,
      title: string,
      target: string,
      isLarge: bool,
    })
  ).isRequired,
  cta: {
    title: string,
    target: string,
  },
  darkMode: bool,
};

FeaturesWithThumbnails.defaultProps = {
  className: "",
  sectionTitle: null,
  cta: null,
  darkMode: false,
};

export default FeaturesWithThumbnails;
