export const hero = {
  title: `Technology guide: how to open a restaurant`,
  subtext: `The rule of thumb has always been it takes 18+ months for a restaurant to reach the break-even point and turn a profit. A little less for quick service restaurants. A good bit longer for fine dining restaurants. But what if there was a faster path to profitability? By leveraging cloud-based restaurant technology, your new restaurant can reach the break-even point quicker than you think.`,
  ctaPrimary: {
    title: "Download the playbook",
    target: "/gated-assets/accelerating-profit-in-new-restaurants",
  },
};

export const checklist = [
  `Build your customer base faster by reaching them online`,
  `Maximize table turns with integrated POS, QR ordering, and handhelds`,
  `Get better data to help you adapt quickly and make smarter decisions`,
  `Avoid costly commissions with direct online ordering and reservations`,
  `Attract and retain staff in a highly competitive labor market`,
];

export const largeFeature1 = {
  sectionTitle: "Resources for opening a new restaurant",
  title: "Build guest relationships",
  subTitle:
    "One of the biggest challenges every new restaurant faces is attracting customers—and turning them into loyal regulars. Make sure you’re leveraging technology right from the start to meet customers online and take ownership of your guest data.",
  features: [
    {
      imageName: "ptp-f-1-1.png",
      title: "Reach guests online with digital reservations or waitlists",
      target:
        "https://spoton.com/blog/rethink-your-waitlist-reservation-solution/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-1-2.png",
      title: "Attract guests and retain them with a digital loyalty program",
      target:
        "https://www.spoton.com/gated-assets/loyalty-program-customer-relationship/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-1-3.png",
      title: "Keep guests coming back with a smart email marketing strategy",
      target:
        "https://spoton.com/blog/email-marketing-best-practices-small-businesses/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-1-4.png",
      title: "Take control of your online reputation",
      target:
        "https://spoton.com/blog/how-to-deal-with-negative-reviews-online/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-1-5.png",
      title: "Make sure you have a well-defined brand from the start",
      target:
        "https://www.spoton.com/gated-assets/restaurant-experience-strengthen-your-brand/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-1-6.png",
      title: "Own your guest data",
      target:
        "https://spoton.com/blog/3-benefits-to-owning-your-customer-data/",
      isLarge: false,
    },
  ],
  cta: {
    title: "Talk to an expert",
    target: "/lp/pathtoprofit/demo",
  },
  darkMode: true,
};

export const largeFeature2 = {
  title: "Operate efficiently",
  subTitle:
    "Want to know how to make a new restaurant successful? Combine your hard work with modern tech that increases productivity and speeds up service while reducing errors and time-consuming tasks.",
  features: [
    {
      imageName: "ptp-f-2-1.png",
      title: "Optimize your menu now to combat inflation and rising food costs",
      target: "https://spoton.com/blog/7-tips-restaurants-fight-inflation/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-2-2.png",
      title: "Get your checklist of key restaurant reports to drive efficiency",
      target:
        "https://spoton.com/blog/restaurant-data-checklist-drive-efficiency/",
      isLarge: true,
    },
    {
      imageName: "ptp-f-2-3.png",
      title: "Speed up service with handheld POS devices",
      target:
        "https://spoton.com/blog/handhelds-transform-full-service-restaurants/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-2-4.png",
      title: "Learn how to calculate your food cost percentage",
      target:
        "https://spoton.com/blog/understanding-restaurants-food-cost-percentage/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-2-5.png",
      title: "Rethink delivery with a POS integration",
      target: "https://spoton.com/blog/the-rising-value-of-food-delivery/",
      isLarge: false,
    },
  ],
  cta: {
    title: "Talk to an expert",
    target: "/lp/pathtoprofit/demo",
  },
  darkMode: false,
};

export const largeFeature3 = {
  title: "Maximize revenue",
  subTitle:
    "How can you get more diners in the door and increase online orders without overpaying for the privilege? Look for integrated technology that lets you keep control of the guest experience, eliminates costly commissions, and works the way you work.",
  features: [
    {
      imageName: "ptp-f-3-1.png",
      title: "Estimate the additional revenue you can earn with technology",
      target: "https://www.spoton.com/profit-margin-calculator",
      isLarge: false,
    },
    {
      imageName: "ptp-f-3-2.png",
      title:
        "Calculate your break-even point to better forecast your financing",
      target: "https://spoton.com/Break-Even-Point-Calculator.pdf",
      isLarge: false,
    },
    {
      imageName: "ptp-f-3-3.png",
      title:
        "Increase sales with commission-free online ordering and Order with Google",
      target: "https://spoton.com/blog/grow-your-bottom-line-online-ordering/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-3-4.png",
      title: "Learn how to turn more tables with QR order & pay",
      target:
        "https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-3-5.png",
      title: "Find out how to seek startup funding",
      target: "https://spoton.com/blog/restaurant-loans-grants/",
      isLarge: true,
    },
  ],
  cta: {
    title: "Talk to an expert",
    target: "/lp/pathtoprofit/demo",
  },
  darkMode: true,
};

export const largeFeature4 = {
  title: "Manage & retain staff",
  subTitle:
    "Hiring and training new staff costs money. Almost $6,000 per employee, according to one study. Simple tools can help you keep staff happy, retain high performers, and save money.",
  features: [
    {
      imageName: "ptp-f-4-1.png",
      title:
        "Reduce employee turnover with tools that simplify tips, payroll, and scheduling",
      target:
        "https://spoton.com/blog/increase-efficiency-labor-management-tool/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-4-2.png",
      title: "Hear how one GM helped her team earn better tips with tech",
      target:
        "https://spoton.com/blog/hillary-holmes-retaining-restaurant-talent-with-tech/",
      isLarge: true,
    },
    {
      imageName: "ptp-f-4-3.png",
      title: "Does the 80/20 tip rule apply to you?",
      target: "https://spoton.com/blog/5-tips-for-restaurants-80-20-tip-rule/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-4-4.png",
      title: "Learn how to operate through staff shortages with tech",
      target: "https://www.spoton.com/gated-assets/self-serve-pos/",
      isLarge: false,
    },
    {
      imageName: "ptp-f-4-5.png",
      title: "Be ready to overcome these 5 HR challenges",
      target:
        "https://spoton.com/blog/common-human-resources-challenges-learn-navigate-them/",
      isLarge: false,
    },
  ],
  cta: {
    title: "Talk to an expert",
    target: "/lp/pathtoprofit/demo",
  },
  darkMode: false,
};

export const largeFeatures = {
  sectionTitle: "Resources for opening a new restaurant",
  featureBlocks: [
    {
      blockTitle: "Build guest relationships",
      blockSubTitle:
        "One of the biggest challenges every new restaurant faces is attracting customers—and turning them into loyal regulars. Make sure you’re leveraging technology right from the start to meet customers online and take ownership of your guest data.",
      blockList: [
        `<a href="https://spoton.com/blog/rethink-your-waitlist-reservation-solution/" target=__blank" class="text-black">Reach guests online with digital reservations or waitlists</a>`,
        `<a href="https://www.spoton.com/gated-assets/loyalty-program-customer-relationship/" target=__blank" class="text-black">Attract guests and retain them with a digital loyalty program</a>`,
        `<a href="https://spoton.com/blog/email-marketing-best-practices-small-businesses/" target=__blank" class="text-black">Keep guests coming back with a smart email marketing strategy</a>`,
        `<a href="https://spoton.com/blog/how-to-deal-with-negative-reviews-online/" target=__blank" class="text-black">Take control of your online reputation</a>`,
        `<a href="https://www.spoton.com/gated-assets/restaurant-experience-strengthen-your-brand/" target=__blank" class="text-black">Make sure you have a well-defined brand from the start</a>`,
        `<a href="https://spoton.com/blog/3-benefits-to-owning-your-customer-data/" target=__blank" class="text-black">Own your guest data</a>`,
      ],
      ctaPrime: {
        ctaTitle: "Talk to an expert",
        ctaTarget: "/lp/pathtoprofit/demo",
      },
      blockImg: "rest-pop-v-1.png",
      // forceReverse: true,
    },
    {
      blockTitle: "Operate efficiently",
      blockSubTitle:
        "Want to know how to make a new restaurant successful? Combine your hard work with modern tech that increases productivity and speeds up service while reducing errors and time-consuming tasks.",
      blockList: [
        `<a href="https://spoton.com/blog/7-tips-restaurants-fight-inflation/" target="__blank" class="text-black">Optimize your menu now to combat inflation and rising food costs</a>`,
        `<a href="https://spoton.com/blog/restaurant-data-checklist-drive-efficiency/" target="__blank" class="text-black">Get your checklist of key restaurant reports to drive efficiency</a>`,
        `<a href="https://spoton.com/blog/handhelds-transform-full-service-restaurants/" target="__blank" class="text-black">Speed up service with handheld POS devices</a>`,
        `<a href="https://spoton.com/blog/understanding-restaurants-food-cost-percentage/" target="__blank" class="text-black">Learn how to calculate your food cost percentage</a>`,
        `<a href="https://spoton.com/blog/the-rising-value-of-food-delivery/" target="__blank" class="text-black">Rethink delivery with a POS integration</a>`,
      ],
      blockImg: "rest-pop-v-2.png",
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Talk to an expert",
        ctaTarget: "/lp/pathtoprofit/demo",
      },
    },
    {
      blockTitle: "Maximize revenue",
      blockSubTitle:
        "How can you get more diners in the door and increase online orders without overpaying for the privilege? Look for integrated technology that lets you keep control of the guest experience, eliminates costly commissions, and works the way you work.",
      blockList: [
        `<a href="https://www.spoton.com/profit-margin-calculator" target="__blank" class="text-black">Estimate the additional revenue you can earn with technology</a>`,
        `<a href="https://spoton.com/Break-Even-Point-Calculator.pdf" target="__blank" class="text-black">Calculate your break-even point to better forecast your financing</a>`,
        `<a href="https://spoton.com/blog/grow-your-bottom-line-online-ordering/" target="__blank" class="text-black">Increase sales with commission-free online ordering and Order with Google</a>`,
        `<a href="https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/" target="__blank" class="text-black">Learn how to turn more tables with QR order & pay</a>`,
        `<a href="https://spoton.com/blog/restaurant-loans-grants/" target="__blank" class="text-black">Find out how to seek startup funding</a>`,
      ],
      blockImg: "rest-pop-v-3.png",
      // forceReverse: true,
      ctaPrime: {
        ctaTitle: "Talk to an expert",
        ctaTarget: "/lp/pathtoprofit/demo",
      },
    },
    {
      blockTitle: "Manage & retain staff",
      blockSubTitle:
        "Hiring and training new staff costs money. Almost $6,000 per employee, according to one study. Simple tools can help you keep staff happy, retain high performers, and save money.",
      blockList: [
        `<a href="https://spoton.com/blog/increase-efficiency-labor-management-tool/" target="__blank" class="text-black">Reduce employee turnover with tools that simplify tips, payroll, and scheduling</a>`,
        `<a href="https://spoton.com/blog/hillary-holmes-retaining-restaurant-talent-with-tech/" target="__blank" class="text-black">Hear how one GM helped her team earn better tips with tech</a>`,
        `<a href="https://spoton.com/blog/5-tips-for-restaurants-80-20-tip-rule/" target="__blank" class="text-black">Does the 80/20 tip rule apply to you?</a>`,
        `<a href="https://www.spoton.com/gated-assets/self-serve-pos/" target="__blank" class="text-black">Learn how to operate through staff shortages with tech</a>`,
        `<a href="https://spoton.com/blog/common-human-resources-challenges-learn-navigate-them/" target="__blank" class="text-black">Be ready to overcome these 5 HR challenges</a>`,
      ],
      blockImg: "rest-pop-v-4.png",
      forceReverse: true,
      ctaPrime: {
        ctaTitle: "Talk to an expert",
        ctaTarget: "/lp/pathtoprofit/demo",
      },
    },
  ],
};

export const heroSuccess = {
  title: "Success stories",
  subTitle: "Customer stories",
};

export const BarbwireBbqSlides = [
  {
    image: "barbwire-bbq-success-story.png",
    companyName: "Barbwire Barbecue",
    companyIs: "Restaurant",
    companyLogo: "barbwire-logo.png",
    address: "Eudora, Kansas",
    owner: "Jason and Jay Musick",
    website: "www.barbwirebarbecue.com",
    logoClassName: "max-w-[60px] max-h-[60px]",
    description:
      "The Musick brothers needed a technology partner to help bring their barbecue concept to life with a brick & mortar location. With SpotOn, they were able to boost efficiency and sales with integrated point-of-sale, online ordering, and digital loyalty.",
    ctaTitle: "Read the story",
    url: "/case-studies/barbwire-barbecue/",
    info: [
      {
        numbers: "40%",
        numberType: "increase in revenue",
      },
      {
        numbers: "$10K",
        numberType: "in monthly loyalty revenue",
      },
    ],
  },
];

export const team = {
  title: "Partner with a team who listens",
  paragraphs: [
    `Technology and data are going to be a key part of opening your new restaurant and reaching profitability faster. But not all technology providers are created equal.`,
    `At SpotOn, we build technology that works the way you work—not the other way around. So we promise flexibility and transparency throughout the process. Because if you’ve got the courage to strike out on your own, you ought to have the freedom to do things the way you want and go as big (or stay as small) as you want.`,
    `You decide how to run your restaurant. We’ll build technology that works the way you work. And we’ll back it up with a 24/7 team of experts who will make sure it always does—with fairness, flexibility, and a personal touch. Because that’s the way we work.`,
  ],
  cta: {
    title: "Learn more",
    target: "/lp/pathtoprofit/demo",
  },
};

export const footer = {
  title: "Book your consultation and save",
  subtext:
    "Talk to one of our restaurant technology experts and learn how you can speed up your path to profitability. Be sure to tell our team that you're a new restaurant operator to receive our special pricing.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/lp/pathtoprofit/demo",
  },
};
